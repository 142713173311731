import { render, staticRenderFns } from "./CardStartup.vue?vue&type=template&id=149353f9"
import script from "./CardStartup.vue?vue&type=script&lang=js"
export * from "./CardStartup.vue?vue&type=script&lang=js"
import style0 from "./CardStartup.vue?vue&type=style&index=0&id=149353f9&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormReportProfile: require('/workspace/components/Form/Report/FormReportProfile.vue').default})
