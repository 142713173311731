
export default {
  props: {
    title: String,
    items: {
      type: Array,
      default: () => [],
    },
    breakpoints: {
      type: Object,
      default: () => ({
        1024: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      }),
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initSwiper();
    });
  },
  watch: {
    item() {
      this.initSwiper();
    },
  },
  methods: {
    initSwiper() {
      const el = this.$refs.featuredSlider;
      Object.assign(el, {
        spaceBetween: 24,
        autoplayDelay: 2500,
        loop: true,
        pagination: {
          clickable: true,
        },
        breakpoints: this.breakpoints,
      });
      el.initialize();
    },
  },
};
