
const colors = ["#326199", "#4fb1a1", "#fcc055", "#eb8d50", "#df6e5b"];
export default {
  props: {},
  data() {
    return {
      colors,
      overview: {
        loading: false,
        dataset: [],
        data: [],
      },
      featured: {
        loading: false,
        dataset: [],
      },
      gender: {
        total: 10,
        female: 2,
      },
      breakpoints: {
        1024: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 2,
        },
      },
    };
  },
  methods: {
    fetchOverview() {
      this.overview.loading = true;
      this.$axios
        .get("/api/v1/data_insight/mentor-overview")
        .then((res) => {
          const byExpertise = res.data.mentor_by_expertise || [];
          // const byGender = res.data.mentor_by_gender || [];
          const byOwnBiz = res.data.mentor_by_investor || [];
          const byPrevEnt = res.data.mentor_by_prev_entrepreneur || [];
          // {
          //   name: this.$t("gender"),
          //   dataset: byGender.map((item) => ({
          //     label: item.label,
          //     value: item.total,
          //   })),
          // },
          this.gender.total = res.data.mentor_by_gender.total || 0;
          this.gender.female = res.data.mentor_by_gender.female || 0;
          const dataset = [
            {
              name: this.$t("previous_entrepreneur"),
              dataset: byPrevEnt.map((item) => ({
                label: item.label,
                value: item.percentage,
              })),
            },
            {
              name: this.$t("mentor_by_investor"),
              dataset: byOwnBiz.map((item) => ({
                label: item.label,
                value: item.percentage,
              })),
            },
          ];
          this.overview.data = byExpertise.map((item) => ({
            ...item,
            name: item.expert?.name,
            value: item.total,
          }));
          this.overview.dataset = Object.assign([], dataset);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.overview.loading = false;
        });
    },
    fetchFeatured() {
      this.featured.loading = true;
      this.$axios
        .get("/api/v1/data_insight/mentor-highlighted")
        .then((res) => {
          this.featured.dataset = res.data.data || [];
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.featured.loading = false;
        });
    },
    onPieItemClick(item) {
      const exp = item?.expert?.name_en;
      window.open(`/ecosystem/discover/category/mentor?expertise=${exp}`);
    },
  },

  mounted() {
    this.fetchOverview();
    this.fetchFeatured();
  },
};
