
export default {
  data() {
    return {
      loading: false,
      showDialog: false,
      stakeholder: {},
      list: [],
    };
  },
  computed: {
    iconsList() {
      return [
        require("@/static/icons/ecosystem/Government Institution_Black.svg?raw"),
        require("@/static/icons/ecosystem/Institution_Black.svg?raw"),
        require("@/static/icons/ecosystem/Service Provider_Black.svg?raw"),
        require("@/static/icons/ecosystem/Development Partner.svg?raw"),
        require("@/static/icons/ecosystem/Co Working Space_Black.svg?raw"),
        require("@/static/icons/ecosystem/Incubator_Black.svg?raw"),
        require("@/static/icons/ecosystem/Community_Black.svg?raw"),
        require("@/static/icons/ecosystem/Corporate_Black.svg?raw"),
        require("@/static/icons/ecosystem/Media_Black.svg?raw"),
      ];
    },
  },
  props: {},
  methods: {
    fetchData() {
      this.loading = true;
      this.$axios
        .get("/api/v1/data_insight/stakeholder")
        .then((res) => {
          const _list = res.data?.data || [];
          this.list = _list.map((item, index) => {
            return {
              ...item,
              icon: this.iconsList[index],
              name: item.name,
              value: item.total,
              // children: item.items || [],
            };
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickChartItem(item) {
      this.stakeholder = item;
      this.showDialog = true;
      // window.open(`/ecosystem/discover/category/${stakeholder}`);
    },
  },
  mounted() {
    this.fetchData();
  },
};
