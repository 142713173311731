
import { mapActions } from "vuex";

export default {
  name: "FormReportProfile",
  data() {
    return {
      dialog: false,
      form: {},
    };
  },
  methods: {
    ...mapActions("stakeholder", ["claimRequest"]),
    openForm(id, type) {
      
      this.dialog = true;
      this.form.id = id; // stakeholder ID
      this.form.type = type; // stakholder type
      this.form.option = "general";
    },
    _saveForm() {
      let valid = this.$refs.form.validate();
      if (!valid) return;
      let payload = {
        stakeholder: this.form.id,
        message: this.form.description,
        email: this.form.email,
        type: this.form.type,
        option: this.form.option,
      };

      

      this.claimRequest(payload)
        .then((res) => {
          
          let message = res;
          this.$swal.fire("", this.$t("success"), "success");
          this._closeForm();
        })
        .catch((error) => {
          const message = error.response.data.message || error;
          this.$swal.fire("Failed!", message, "error");
        });
    },
    _closeForm() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
