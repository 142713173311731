
export default {
  props: {
    title: String,
    subtitle: String,
    loading: Boolean,
    dark: Boolean,
    color: String,
    thumbnail: String,
    textClass: String,
  },
};
