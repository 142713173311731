
import * as echarts from "echarts";

const colors = [
  "#334d9a",
  "#3a56ad",
  "#4160c0",
  "#5470c6",
  "#6780cc",
  "#7a90d3",
  "#8da0d9",
];
export default {
  props: {
    title: String,
    loading: Boolean,
    showLegend: Boolean,
    showPercentage: Boolean,
    colors: { type: Array, default: () => colors },
    dataset: { type: Array, default: () => [] },
  },
  data() {
    return {
      chart: undefined,
    };
  },
  mounted() {
    this.updateChart();
    this.$nextTick(() => {
      window.addEventListener("resize", this.updateChart);
    });
  },
  computed: {
    totalValue() {
      return this.dataset.reduce((acc, item) => acc + item.value, 0);
    },
  },
  watch: {
    dataset: {
      handler() {
        this.updateChart();
      },
    },
  },
  methods: {
    getOptions() {
      return {
        color: this.colors,
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "90%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: this.showPercentage,
              position: "inside",
              formatter: "{d}%",
            },
            emphasis: {
              label: {
                show: false,
              },
            },
            labelLine: {
              show: false,
            },
            data: this.dataset,
          },
        ],
      };
    },
    initChart() {
      const el = this.$refs.chartElement;
      this.chart = echarts.init(el);
      this.chart.on("click", (params) => {
        this.$emit("click-chart-item", params.data);
      });
    },
    updateChart() {
      if (!this.chart) {
        this.initChart();
      }
      this.chart.setOption(this.getOptions());
      this.chart.resize();
    },
  },
};
