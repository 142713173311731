
import { count } from "d3";

export default {
  props: {
    title: String,
    subtitle: String,
    loading: Boolean,
    dark: Boolean,
    color: String,
    total: { type: Number, default: 0 },
    femaleCount: { type: Number, default: 0 },
  },
  computed: {
    percentage() {
      if (!this.total) return 0;
      return +((this.femaleCount / this.total) * 100).toFixed(2);
    },
    ratingValue() {
      if (!this.total) return 0;
      return Math.ceil((this.femaleCount / this.total) * 5);
    },
  },
};
