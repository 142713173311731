
// import * as echarts from "echarts";
const colors = [
  "#334d9a",
  "#4160c0",
  "#744da2",
  "#a8489d",
  "#d4458e",
  "#f44c76",
  "#ff6358",
  "#ff8337",
  "#ffa600",
];
export default {
  props: {
    title: String,
    loading: Boolean,
    colors: { type: Array, default: () => colors },
    dataset: { type: Array, default: () => [] },
    cols: { type: [Number, String], default: 4 },
  },
  data() {
    return {
      chart: undefined,
    };
  },
  mounted() {
    this.updateChart();
    this.$nextTick(() => {
      window.addEventListener("resize", this.updateChart);
    });
  },
  computed: {
    totalValue() {
      return this.dataset.reduce((acc, item) => acc + item.value, 0);
    },
  },
  watch: {
    dataset: {
      handler() {
        this.updateChart();
      },
    },
  },
  methods: {
    getOptions() {
      return {
        color: this.colors,
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "treemap",
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 4,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: true,
              position: "inner",
            },
            breadcrumb: {
              show: false,
            },
            roam: false,
            nodeClick: false,
            data: this.dataset,
          },
        ],
      };
    },
    initChart() {
      // const el = this.$refs.chartElement;
      // this.chart = echarts.init(el);
      // this.chart.on("click", (params) => {
      //   console.log(this.dataset);
      //   this.$emit("click-chart-item", params.data);
      // });
    },
    updateChart() {
      // if (!this.chart) {
      //   this.initChart();
      // }
      // this.chart.setOption(this.getOptions());
      // this.chart.resize();
    },
  },
};
