
export default {
  name: "CardViewMentor",
  data() {
    return {
      show: false,
    };
  },
  props: {
    id: Number,
    to: { type: String, default: "" },
    fullName: String,
    position: String,
    company: String,
    interest: String,
    expertise: { type: Array, default: () => [] },
    thumbnail: { type: String, default: "/images/default-user.png" },
  },
  computed: {
    expertiseList() {
      if (!this.expertise?.length) return [];
      return [this.expertise[0]];
    },
  },
  methods: {
    onClickTag(item) {
      this.$emit("click-expertise", item);
    },
  },
};
