import { render, staticRenderFns } from "./EcosystemMentor.vue?vue&type=template&id=25fbab56"
import script from "./EcosystemMentor.vue?vue&type=script&lang=js"
export * from "./EcosystemMentor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardStakedColumnsChart: require('/workspace/components/Charts/CardStakedColumnsChart.vue').default,CardChartGender: require('/workspace/components/CardView/CardChartGender.vue').default,CardMentor: require('/workspace/components/CardView/CardMentor.vue').default,FeaturedSlider: require('/workspace/components/sections/FeaturedSlider.vue').default})
