
export default {
  props: {
    title: String,
    loading: Boolean,
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      chart: undefined,
    };
  },
  computed: {
    swiperItems() {
      if (!this.items.length) return [];

      const items = [[this.items[0]]];
      let tempIdx = 1;
      let tempCount = 2;
      while (tempIdx < this.items.length) {
        items.push(this.items.slice(tempIdx, tempIdx + tempCount));
        tempIdx += tempCount;
        if (tempCount === 2) {
          tempCount = 3;
        } else {
          tempCount = 2;
        }
      }
      return items;
    },
  },
  methods: {},
};
