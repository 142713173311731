
export default {
  data() {
    return {
      loading: false,
      programs: {
        dataset: [],
        datasetByFields: [],
      },
      events: {
        dataset: [],
        highlights: [],
      },
    };
  },
  computed: {
    iconsList() {
      return [
        require("@/static/icons/ecosystem/Incubator_Black.svg?raw"),
        require("@/static/icons/ecosystem/Investor_Black.svg?raw"),
        require("@/static/icons/ecosystem/Rocket.svg?raw"),
        require("@/static/icons/ecosystem/Corporate_Black.svg?raw"),
        require("@/static/icons/ecosystem/Media_Black.svg?raw"),
        require("@/static/icons/ecosystem/Service Provider_Black.svg?raw"),
        require("@/static/icons/ecosystem/Development Partner.svg?raw"),
        require("@/static/icons/ecosystem/Co Working Space_Black.svg?raw"),
        require("@/static/icons/ecosystem/Community_Black.svg?raw"),
      ];
    },
  },

  methods: {
    fetchOverview() {
      this.loading = true;
      this.$axios
        .get("/api/v1/data_insight/program", {
          params: { highlighted_count: 16 },
        })
        .then((response) => {
          const programs = response.data?.program_by_type?.data || [];
          console.log("programs=====", programs);
          const programByFields = response.data?.program_by_fields?.data || [];
          const eventByFields = response.data?.event_by_fields?.data || [];
          const events = response.data?.highlighted_events || [];
          this.programs.dataset = programs.map((item) => ({
            ...item,
            name: item.name,
            value: item.total,
          }));
          this.programs.datasetByFields = programByFields.map(
            (item, index) => ({
              name: item.name || this.$t("others"),
              icon: this.iconsList[index],
              name_en: item.name_en,
              value: item.total,
              slug: item.slug,
            })
          );
          this.events.dataset = eventByFields.map((item) => ({
            name: item.name,
            value: item.total,
          }));

          this.events.highlights = events;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onTreeMapItemClick(item) {
      this.$router.push({
        path: "/program",
        query: { category: encodeURIComponent(item.slug) },
      });
    },
    onProgramTypeClick(item) {
      let _type = "";
      if (item.name_en == "National") _type = "NTL";
      if (item.name_en == "International") _type = "GLO";

      this.$router.push({ path: "/program", query: { program_type: _type } });
    },
  },
  mounted() {
    this.fetchOverview();
  },
};
