
import FormReportProfile from "@/components/Form/Report/FormReportProfile";
export default {
  components: { FormReportProfile },
  data() {
    return {
      show: false,
    };
  },
  props: {
    id: Number,
    to: { type: String, default: "" },
    stage: Object,
    title: String,
    incorporatedAt: String,
    bizModel: String,
    establishYear: [String, Number],
    models: { type: Array, default: () => [] },
    thumbnail: { type: String, default: "/images/default-image.png" },
    sectors: { type: Array, default: () => [] },
  },
  computed: {
    noSector() {
      return !this.sectors.length;
    },
    noModel() {
      return !this.models.length;
    },
    stages() {
      return {
        pre_seed: { id: "pre_seed", name: "Pre Seed" },
        seed: { id: "seed", name: "Seed" },
        pre_series_a: { id: "pre_series_a", name: "Pre Series A" },
        series_a: { id: "series_a", name: "Series A" },
        series_b: { id: "series_b", name: "Series B" },
        series_c: { id: "series_c", name: "Series C" },
      };
    },
    fundingStage() {
      return this.stages[this.stage] || { id: this.stage, name: this.stage };
    },
  },
  methods: {
    clickStage() {
      this.$emit("click-stage", this.stage);
    },
    clickSector(item) {
      this.$emit("click-industry", item);
    },
    clickModel(item) {
      this.$emit("click-model", item);
    },
    _showReportForm() {
      this.$refs.formReport.openForm(this.id, "startup");
    },
    onLoadImgError(e) {
      e.target.src = "/images/default-image.png";
    },
  },
};
