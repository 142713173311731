import { render, staticRenderFns } from "./EcosystemStartup.vue?vue&type=template&id=2cfc2744"
import script from "./EcosystemStartup.vue?vue&type=script&lang=js"
export * from "./EcosystemStartup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardPieChart: require('/workspace/components/Charts/CardPieChart.vue').default,CardColumnChart2: require('/workspace/components/Charts/CardColumnChart2.vue').default,CardBarChart: require('/workspace/components/Charts/CardBarChart.vue').default,CardStartup: require('/workspace/components/CardView/CardStartup.vue').default,FeaturedSlider: require('/workspace/components/sections/FeaturedSlider.vue').default})
